const data = [
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic1.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic2.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic3.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic4.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic5.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic6.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img1.JPG",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img2.JPG",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img3.JPG",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img4.JPG",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img5.JPG",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img6.JPG",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img7.JPG",

  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic4.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic5.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic6.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic7.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic8.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic9.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic10.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic11.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic12.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024-2/pic13.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024/pic14.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024/pic15.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024/pic16.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024/pic17.jpg",
  // "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/2024/pic18.jpg",
].reverse();

export default data.reverse();
